import { AlertCircle } from 'lucide-react';
import { FC } from 'react';
import { Button } from '../ui/button';

import { useNavigate } from 'react-router';

interface ErrorProps {
  error?: unknown;
}

export const Error: FC<ErrorProps> = ({ error }) => {
  const navigate = useNavigate();
  const handleGoToHome = () => navigate('/rooms')

  console.log('Error.tsx [10]', error);
  return <div className="flex flex-col items-center justify-center min-h-screen bg-background text-foreground p-4">
    <div className="text-center space-y-6 max-w-md">
      <div>
        <AlertCircle className="w-24 h-24 mx-auto text-destructive"/>
      </div>
      <h1 className="text-4xl font-bold">Oops! Something went wrong</h1>
      <p className="text-xl text-muted-foreground">
        We are sorry, but it seems there was an error processing your request.
      </p>
      <Button variant="default" onClick={handleGoToHome} className="mt-4">
        Return to Home
      </Button>
    </div>
  </div>
};
