import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError, } from '@remix-run/react';
import { json, LinksFunction, LoaderFunctionArgs, redirect } from '@remix-run/node';

import './tailwind.css';
import { authenticator } from '~/services/auth.server';
import { ReactNode, useState } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Configuration } from '~/types/Configuration';
import { Error } from '~/components/error/Error';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { baseQueryClient } from '~/lib/react-query/configuration';

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
  },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await authenticator.isAuthenticated(request);
  if (!user) {
    const url = new URL(request.url);
    if (url.pathname !== '/login') {
      return redirect('/login', 302);
    }
  }

  const ENV = {
    KROVY_API_URL: Configuration.KROVY_API_URL
  };
  return json({
    user,
    ENV
  })
}

export function Layout({ children }: { children: ReactNode }) {
  return (
    <html lang="en" className="dark">
      <head>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <Meta/>
        <Links/>
      </head>
      <body>
        {children}
        <ScrollRestoration/>
        <Scripts/>
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <Error error={error}/>;
};

export default function App() {
  const { ENV } = useLoaderData<typeof loader>();
  const [queryClient] = useState(() => baseQueryClient)
  return <QueryClientProvider client={queryClient}>
    <Outlet/>
    <script dangerouslySetInnerHTML={{
      __html: `window.ENV = ${JSON.stringify(ENV)}`
    }}/>
    <ReactQueryDevtools/>
  </QueryClientProvider>;
}
